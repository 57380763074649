import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCoupons(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_coupons', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCoupon(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_coupon', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCoupon(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ecoupon/add_coupon', params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editCoupon(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/edit_coupon/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCoupon(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/delete_coupon/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageCoupon(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/upload_image_coupon/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageEnCoupon(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/ecoupon/upload_image_en_coupon/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultCoupon(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecoupon/get_default_coupon', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importCoupon(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ecoupon/import_coupon', params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
